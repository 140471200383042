import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Utils
import ThirdPartyAuthService from 'Webapp/shared/utils/third-party-auth-service';

// Components
import FacebookIcon from 'ComponentLibrary/icons/facebook';
import Button, { StyleModifiers } from 'Webapp/shared/app/components/button';

class FacebookLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fbAuth: {},
      isLoading: false,
      sdkError: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleAuth = this.handleAuth.bind(this);
    this.checkLoginStatus = this.checkLoginStatus.bind(this);
  }

  async componentDidMount() {
    try {
      await ThirdPartyAuthService.loadFacebookSDK();
      this.checkLoginStatus();
    } catch (_) {
      this.setState({ sdkError: true });
    } finally {
      this.setState({ isLoading: false });
    }
  }

  checkLoginStatus() {
    ThirdPartyAuthService.getFacebookAuthStatus((res) =>
      this.setState({ fbAuth: res }),
    );
  }

  handleClick() {
    if (this.state.isLoading) {
      return;
    }
    this.setState({ isLoading: true });
    if (this.state.fbAuth.status === 'connected') {
      this.props.onAuth('facebook', this.state.fbAuth.authResponse.accessToken);
    } else {
      ThirdPartyAuthService.loginWithFacebook(
        this.handleAuth,
        this.props.isSignup,
      );
    }
  }

  handleAuth(auth) {
    if (auth && auth.status === 'connected') {
      this.props.onAuth('facebook', auth.authResponse.accessToken);
    } else {
      this.setState({ isLoading: false });
      this.props.onError('facebook');
    }
  }

  render() {
    if (this.state.sdkError) {
      return null;
    }
    const { className } = this.props;
    const buttonClassName = classNames(
      className,
      'button--with-badge--social',
      'button--with-badge--auth',
      'button--with-badge--facebook--auth',
    );
    return (
      <Button
        name="facebook-login"
        className={buttonClassName}
        styleModifier={[StyleModifiers.WITH_BADGE, StyleModifiers.LITTLE]}
        disabled={this.state.isLoading}
        onClick={this.handleClick}
      >
        <FacebookIcon useBrandColor />
      </Button>
    );
  }
}

FacebookLogin.propTypes = {
  className: PropTypes.string,
  onAuth: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  isSignup: PropTypes.bool,
};

FacebookLogin.defaultProps = {
  isSignup: false,
};

export default FacebookLogin;
