import connector from 'Utils/connector';
import LoginForm from 'Webapp/shared/app/components/auth/login-form';
import { setIsChromeless } from 'Webapp/shared/app/redux/actions/app';
import {
  usageTrackLoginEnter,
  usageTrackLoginExit,
} from 'Webapp/shared/app/redux/actions/usage-actions';
import {
  authFormUpdate,
  connectWithSSO,
  setSSOError,
  setErrorMessage,
  populateAuthentication,
  login,
} from 'Webapp/shared/app/redux/actions/auth-actions';
import { isPhone } from 'Webapp/shared/app/redux/selectors/viewport';
import { authForm } from 'Webapp/shared/app/redux/selectors/auth';

export default connector({
  selectors: {
    authForm,
    logoSize: (state) => (isPhone(state) ? 40 : 80),
  },
  actions: {
    authFormUpdate,
    connectWithSSO,
    populateAuthentication,
    setIsChromeless,
    usageTrackLoginEnter,
    usageTrackLoginExit,
    setSSOError,
    setErrorMessage,
    login,
  },
})(LoginForm);
