import React from 'react';
import PropTypes from 'prop-types';
import Config from 'Config';

import getWindow from 'Utils/get-window';
import sentry from 'Utils/sentry';
import logger from 'Utils/logger';
import { getCookieValue } from 'Utils/client-cookie';
import { GA } from 'Utils/analytics';
import { Services } from 'Webapp/enums';

// Components
import Button from 'Webapp/shared/app/components/button';
import AuthModuleTitle from 'Webapp/shared/app/components/auth/auth-module-title';
import Input from 'Webapp/shared/app/components/base/input';
import SSOLogins from 'Webapp/shared/app/components/auth/sso-logins';
import TermsOfUseAndPrivacyPolicy from 'Webapp/shared/app/components/auth/terms-of-use-and-privacy-policy';
import AuthModule from 'Webapp/shared/app/components/auth/auth-module';
import Message from 'ComponentLibrary/typography/message';
import FlipboardLogo from 'ComponentLibrary/logos/flipboard';
import WebLink from 'Webapp/shared/app/components/web-link';

import styled from '@emotion/styled';
import { UI_TEXT_TYPES } from 'Style/typography';
import { BREAKPOINTS } from 'Style/breakpoints';
import { SPACING } from 'Style/spacing';

import connector from 'Utils/connector';
import connectRouting from 'Webapp/shared/app/connectors/connectRouting';

import withReCaptcha from 'Webapp/shared/app/hocs/withReCaptcha';
import withT from 'ComponentLibrary/hocs/withT';
import withHistory from 'Webapp/shared/app/hocs/withHistory';

const LoginAuthModule = styled(AuthModule)(
  BREAKPOINTS.tabletPortraitUp({
    width: '360px',
    borderRadius: '5px',
  }),
  {
    '.logo--icon': {
      marginBottom: SPACING.LARGE,
    },

    '.field-block': {
      marginBottom: SPACING.LARGE,
    },
  },
);

const StyledGetLoginButton = styled(Button)({
  marginBottom: SPACING.BASE4X,
});

const StyledLoginAltActions = styled.div({
  marginBottom: SPACING.LARGE,
  textAlign: 'right',
});

const StyledLoginAltMessage = styled.p(UI_TEXT_TYPES.SUPPORTING, {
  marginBottom: SPACING.LARGE,
});

const StyledLoginText = styled.p(UI_TEXT_TYPES.SUPPORTING);

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handlePasswordCredentials = this.handlePasswordCredentials.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLoginWithSSOToken = this.handleLoginWithSSOToken.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
  }

  componentDidMount() {
    const { usageTrackLoginEnter } = this.props;
    usageTrackLoginEnter();
    try {
      if (!getWindow().PasswordCredential) {
        return;
      }
      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Feature-Policy/publickey-credentials-get
      // https://developers.google.com/web/updates/2018/03/webauthn-credential-management
      if (getWindow().navigator.credentials) {
        getWindow()
          .navigator.credentials.get({ password: true, mediation: 'optional' })
          .then(this.handlePasswordCredentials)
          .catch(logger.error);
      }
    } catch (_) {
      // noop
    }
  }

  handlePasswordCredentials(credentials) {
    if (!credentials) {
      return;
    }
    this.props.authFormUpdate({
      username: credentials.name || credentials.id,
      password: credentials.password,
    });
  }

  handleLoginWithSSOToken(service, token) {
    const { connectWithSSO } = this.props;
    connectWithSSO(service, token).then((response) => {
      getWindow().location = response.data.redirectTo;
    });
  }

  handleChange(e) {
    this.props.authFormUpdate({ [e.target.name]: e.target.value });
  }

  async handleLogin() {
    const {
      authForm: { username, password },
      setErrorMessage,
      usageTrackLoginExit,
      populateAuthentication,
      login,
      redirectAfterAuth,
      onComplete,
      history,
      t,
    } = this.props;
    if (!username || !password) {
      return setErrorMessage(
        t('forgot_password_error_message_invalid_credentials'),
      );
    }
    const response = await login(username, password);
    if (!response || response.error) {
      await usageTrackLoginExit(false, Services.FLIPBOARD);
      GA.trackLogin(false, Services.FLIPBOARD);
      return;
    }
    try {
      await usageTrackLoginExit(true, Services.FLIPBOARD);
      GA.trackLogin(true, Services.FLIPBOARD);
      await populateAuthentication();
      if (redirectAfterAuth) {
        const redirectPath = getCookieValue('redirect_after_auth');
        history.push(redirectPath || '/');
      }
      if (onComplete) {
        onComplete();
      }
    } catch (e) {
      GA.trackLogin(false, Services.FLIPBOARD);
      sentry.captureException(e);
      getWindow().location = '/';
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const { validateReCaptcha } = this.props;
    await validateReCaptcha('login', this.handleLogin);
  }

  render() {
    const {
      className,
      setSSOError,
      ReCaptchaUI,
      isReCaptchaPending,
      authForm: { username, password, isLoading, errorMessage },
      onRequestLoginLink,
      t,
    } = this.props;
    const isSubmittable =
      username && password && !isLoading && !isReCaptchaPending;
    return (
      <LoginAuthModule className={className}>
        <FlipboardLogo size={40} />
        <AuthModuleTitle>{t('log_in_to_flipboard')}</AuthModuleTitle>
        <SSOLogins
          onLoginWithSSOToken={this.handleLoginWithSSOToken}
          onSSOError={setSSOError}
        />
        <StyledGetLoginButton
          name="login__get-login-button"
          className="button--secondary"
          href="/request-login-link"
          onClick={onRequestLoginLink}
        >
          {t('get_log_in_link')}
        </StyledGetLoginButton>
        <StyledLoginAltMessage>{t('or_with_email')}</StyledLoginAltMessage>
        <form onSubmit={this.handleSubmit}>
          <div className="field-block">
            <Input
              type="text"
              name="username"
              placeholder={t('username_or_email')}
              value={username}
              onChange={this.handleChange}
              disabled={isLoading}
            />
          </div>
          <div className="field-block">
            <Input
              name="password"
              type="password"
              placeholder={t('password')}
              value={password}
              onChange={this.handleChange}
              disabled={isLoading}
            />
          </div>
          <StyledLoginAltActions>
            {/* TODO: re-introudce QR-code if needed: JIRA: FL-24363 */}
            <WebLink
              name="login__forgot-password"
              className="ui-text--supporting"
              href={`${Config.FLIPBOARD_ACCOUNTS_URL}/accounts/forgotPassword`}
            >
              {t('forgot_your_password')}
            </WebLink>
          </StyledLoginAltActions>
          {ReCaptchaUI}
          <Button
            name="login__form-submit"
            className="button--primary button--block"
            disabled={!isSubmittable}
            type="submit"
            loading={isLoading}
          >
            {t('log_in')}
          </Button>
          <Message>{errorMessage}</Message>
        </form>
        <StyledLoginText>
          {t('new_to_flipboard')}{' '}
          <WebLink name="login__sign-up-link" href="/signup">
            {t('sign_up')}
          </WebLink>
        </StyledLoginText>
        <TermsOfUseAndPrivacyPolicy />
      </LoginAuthModule>
    );
  }
}

Login.propTypes = {
  className: PropTypes.string,
  authFormUpdate: PropTypes.func.isRequired,
  usageTrackLoginEnter: PropTypes.func.isRequired,
  usageTrackLoginExit: PropTypes.func.isRequired,
  validateReCaptcha: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  setSSOError: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  connectWithSSO: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
  redirectAfterAuth: PropTypes.bool,
  populateAuthentication: PropTypes.func.isRequired,
  onRequestLoginLink: PropTypes.func,
  isReCaptchaPending: PropTypes.bool.isRequired,
  ReCaptchaUI: PropTypes.node,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  authForm: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool.isRequired,
  }),
};

Login.defaultProps = {};

export default connector(connectRouting)(
  withHistory(withReCaptcha(withT(Login))),
);
