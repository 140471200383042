import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_HEADING_TYPES } from 'Style/typography';
const StyledTitle = styled.h1(UI_HEADING_TYPES.MEDIUM, {
  marginBottom: SPACING.LARGE,
});
const AuthModuleTitle = ({ children }) => <StyledTitle>{children}</StyledTitle>;

AuthModuleTitle.propTypes = {
  children: PropTypes.string.isRequired,
};

export default AuthModuleTitle;
