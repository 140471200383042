import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Config from 'Config';
import AppleLogin from 'Webapp/shared/app/components/auth/sso-logins/apple-login';
import FacebookLogin from 'Webapp/shared/app/components/auth/sso-logins/facebook-login';
import GoogleLogin from 'Webapp/shared/app/components/auth/sso-logins/google-login';
// import TwitterLogin from 'Webapp/shared/app/components/auth/sso-logins/twitter-login';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { SPACING } from 'Style/spacing';

const StyledSSOLogins = styled.div({
  display: 'flex',
  gap: SPACING.MEDIUM,
  justifyContent: 'center',
  marginBottom: SPACING.LARGE,
});

function SSOLogins({ onLoginWithSSOToken, onSSOError }) {
  return (
    <StyledSSOLogins>
      <AppleLogin onAuth={onLoginWithSSOToken} onError={onSSOError} />
      <FacebookLogin onAuth={onLoginWithSSOToken} onError={onSSOError} />
      <GoogleOAuthProvider clientId={Config.GOOGLE_SDK_APP_ID}>
        <GoogleLogin onAuth={onLoginWithSSOToken} onError={onSSOError} />
      </GoogleOAuthProvider>
      {/* Does not work and may never come back? Twitter is in Elon chaos mode. <TwitterLogin /> */}
    </StyledSSOLogins>
  );
}

SSOLogins.propTypes = {
  onLoginWithSSOToken: PropTypes.func.isRequired,
  onSSOError: PropTypes.func.isRequired,
};

export default SSOLogins;
